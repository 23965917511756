import * as React from "react"
import HelmetSlot from "../components/Helmet"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"
import Contact from "../components/Contact"
import "../theme/main.less"

const KontaktPage = () => {
  return (
    <>
      <HelmetSlot
        title="Anfahrt und Kontakt"
        description="Telefon: 02241 1696682  E-Mail: info@gt-ag.de - Hier finden Sie weitere Kontaktdetails und wie Sie uns erreichen können."
      />
      <main>
        <body className="kontaktpage">
          <div className="view">
            <div className="landing">
              <Navigation />
            </div>
            <div className="main">
              <h1>Kontakt</h1>
              <div className="content">
                <div className="inner">
                  <h4>Anschrift:</h4>
                  <b>
                    <p>Gebäudetechnik Alexander Gast</p>
                  </b>
                  <p>Hauptstraße 14</p>
                  <p>53721 Siegburg</p>
                  <p>Tel. 02241 1696682</p>
                  <p>Fax 02241 1696683</p>
                  <p>
                    Mail&nbsp;
                    <a href="mailto:info@gt-ag.de" alt="email">
                      info@gt-ag.de
                    </a>
                  </p>
                  <br />
                  <div>
                    <h4>Öffnungszeiten:</h4>
                    <p>Montag bis Donnerstag 7:30 bis 17:00 Uhr</p>
                    <p>Freitags 7:30 bis 14:00 Uhr</p>
                    <p>Oder gerne nach Vereinbarung!</p>
                  </div>
                </div>
                <div className="divider"></div>
                <Contact />
              </div>
            </div>
            <Footer />
          </div>
        </body>
      </main>
    </>
  )
}

export default KontaktPage
